<template>
  <section class="home-testimonials">
    <h2>Testimonials</h2>
    <div>
      <button @click.stop.prevent="prevSlide"><font-awesome-icon :icon="['fas', 'arrow-left']" /></button>
      <div v-for="(review, index) in reviews" :key="index" :class="{ 'testimony-slide-active': index === activeSlide, testimony: index != activeSlide }">
        <h2>{{ review.author_name }}</h2>
        <img :src="review.profile_photo_url" alt="" />
        <div class="rating">
          <span v-for="value in review.rating" :key="value">
            <font-awesome-icon :icon="['fas', 'star']" />
          </span>
        </div>
        <p>{{ review.text }}</p>
      </div>
      <button @click.stop.prevent="nextSlide"><font-awesome-icon :icon="['fas', 'arrow-right']" /></button>
    </div>
  </section>
</template>

<script>
export default {
  name: "CarouselItem",
  components: {},

  data() {
    return {
      reviews: [],
      activeSlide: 0,
    };
  },
  methods: {
    prevSlide() {
      if (this.activeSlide === 0) {
        this.activeSlide = this.reviews.length - 1;
        // console.log("Previous slide is:", this.activeSlide);
      } else {
        this.activeSlide -= 1;
        
      }
    },
    nextSlide() {
      if (this.activeSlide === this.reviews.length - 1) {
        this.activeSlide = 0;
        // console.log("Next slide is:", this.activeSlide);
      } else {
        this.activeSlide += 1;
        
        
      }
    },
  },
  async created() {
    try {
      const response = await fetch("http://localhost:4242/reviews");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      this.reviews = data.result.reviews;
      // console.log("data fetched and pushed to reviews array", this.reviews);
    } catch (error) {
      console.log("Fetch error: ", error);
    }
  },
};
</script>

<style lang="scss" scoped>
.home-testimonials {
  display: flex;
  margin: 2rem;
  flex-direction: column;
  min-height: 40vh;
  // background: var(--background-fade);
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    button {
      height: 20rem;
      background: var(--background-colour);
      color: var(--text-colour);
      padding: 1rem;
      border: none;
      border-radius: 5px;
      margin: 1rem;
      cursor: pointer;
      transition: all 500ms ease-in-out;
    }
    button:hover {
      box-shadow: var(--box-shadow);
      scale: 1.1;
    }
    .testimony {
      display: none;
    }
    .testimony-slide-active {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 80%;
      height: 100%;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 5px;
      // box-shadow: var(--box-shadow);
      margin: 3rem 3rem;
      transition: all 1000ms ease-in-out;
      cursor: pointer;
      h2 {
        color: #1a0dab;
        font-size: 20px;
      }
      img {
        width: 2rem;
        box-shadow: none;
      }
      p {
        color: #333;
        font-size: 14px;
        margin: 0;
      }
      .rating {
        color: var(--text-colour);
        padding: 1rem;
        scale: 1.1;
      }
    }
  }
  .testimony-slide-active:hover {
    // border: 1px solid #ddd;
    // cursor: url('../../public/images/cabbage.svg'), auto;
    box-shadow: var(--box-shadow);
  }
}
@media only screen and (orientation: portrait) {
  .home-testimonials {
    height: 50vh;
    margin: 1rem;
    div {
      button {
        padding: 0.5rem;
        margin: 0.5rem;
      }
      .testimony-slide-active {
        width: 90%;
        padding: 10px;
        margin: 1rem;
        
        .rating {
          padding: 0.5rem;
        }
      }
    }
  }
}
</style>
