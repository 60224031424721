<template>
  <div id="app">
    <NavBar />
    <div class="content">
      <router-view />
    </div>
    <FooterItem />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import FooterItem from "./components/FooterItem.vue";

export default {
  components: {
    NavBar,
    FooterItem,
  },
};
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content {
  flex: 1 0 auto;
}
body {
  text-align: left;
  color: var(--text-colour);
  background-color: var(--background-colour);
  margin: 0;
  scroll-behavior: smooth;
  h1 {
    font-family: "EarthToOvenFont";
    font-size: 3rem;
    letter-spacing: 1px;
    text-wrap: wrap;
    color: var(--text-colour);
    opacity: 1;
  }
  h2 {
    font-family: "Apex mk3-regular";
    font-size: 2.5rem;
    font-weight: 500;
    letter-spacing: 1px;
    opacity: 1;
  }
  h3 {
    font-family: "Avenir";
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-decoration: none;
    opacity: 1;
    margin: 0;
  }
  h4 {
    font-family: "Avenir";
    font-size: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-decoration: none;
    opacity: 1;
    margin: 0;
  }
  h5 {
    font-family: "Apex mk3-regular";
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-decoration: none;
    opacity: 1;
    margin: 0;
  }
  p,
  .paragraph,
  form,
  input,
  label,
  li {
    letter-spacing: 1px;
    // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-family: "Avenir", monospace;
    font-weight: 300;
    font-size: 1.5rem;
    opacity: 1;
  }
  a {
    letter-spacing: 1px;
    // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-family: "Apex Mk3-Regular", Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-size: x-larger;
    color: var(--text-colour);
  }
  button {
    font-family: "Apex Mk3-Regular", Helvetica, Arial, sans-serif;
    border-radius: 2.5px;
    font-size: 1rem;
    cursor: pointer;
    &:active {
      transform: scale(0.95);
    }
  }
  img {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  }
  #caption {
    font-family: "Avenir";
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 1px;
    opacity: 1;
    margin: 0 0 1rem 0;
  }
}
@media only screen and (orientation: portrait) {
  body {
    background-color: none;
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.5rem;
    }
    h3 {
      font-size: 1.25rem;
    }
    h4 {
      font-size: 1rem;
    }
    p,
    .paragraph {
      font-size: 1rem;
    }
    a {
      font-size: 1rem;
    }
    button {
      font-size: 1rem;
    }

    h1 {
      word-wrap: break-word;
    }
    .content {
      padding: 0rem;
    }
  }
}
</style>
