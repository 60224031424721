import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { title: "E.T.O Home" }
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/AboutView.vue"),
    meta: { title: "About" }
  },
  {
    path: "/getintouch",
    name: "getintouch",
    component: () => import("@/views/HireUsView.vue"),
    meta: { title: "Get In Touch" }
  },
  // {
  //   path: "/shop",
  //   name: "shop",
  //   component: () => import("@/views/ShopView.vue"),
  //   meta: { title: "Shop" }
  // },
  {
    path: "/menus",
    name: "menus",
    component: () => import("@/views/MenusView.vue"),
    meta: { title: "Menus" }
  },
  {
    path: "/gallery",
    name: "gallery",
    component: () => import("@/components/Gallery.vue"),
    meta: { title: "Gallery" }
  },
  {
    path: "/tsAndCs",
    name: "tsAndCs",
    component: () => import("@/views/TsandCs.vue"),
    meta: { title: "Terms of Service" }
  },
  {
    path: "/PrivacyPolicy",
    name: "PrivacyPolicy",
    component: () => import("@/views/PrivacyPolicy.vue"),
    meta: { title: "Privacy Policy" }
  },
  // {
  //   path: "/basket",
  //   name: "basket",
  //   component: () => import("@/components/Cart.vue"),
  //   meta: { title: "Your Basket" }
  // },
  {
    path: "/services",
    name: "services",
    component: () => import("@/components/Services.vue"),
    meta: { title: "Events" }
  },
  // {
  //   path: "/meals",
  //   name: "meals",
  //   component: () => import("@/components/mealPrep.vue"),
  // },
  {
    path: "/PaymentSuccess",
    name: "PaymentSuccess",
    component: () => import("@/components/PaymentSuccess.vue"),
    meta: { title: "" }
  },
  // {
  //   path: "/checkOut",
  //   name: "checkOut",
  //   component: () => import("@/components/CheckOut.vue"),
  //   meta: { title: "Checkout" }
  // },
  // {
  //   path: "/admin",
  //   name: "adminItem",
  //   component: () => import("@/components/Admin.vue"),
  //   meta: { title: "Admin" }
  // },
  // {
  //   path: "/products",
  //   name: "productsItem",
  //   component: () => import("@/components/Products.vue"),
  //   meta: { title: "Products" }
  // },
];

// Create the router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active-link',
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

// Set up the navigation guard
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = 'E.T.O'; // Set a default title if none is provided
  }
  next();
});

export default router;
